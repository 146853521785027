import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { handleArrayParameters, removeNullAndUndefined } from '../helpers';
import { ApiQueryParametersInterface, ApiResponse, QueryParametersInterface } from '../interfaces';
import { EnvironmentService } from '@nutt/configuration';

const TOTAL_COUNT_HEADER_KEY = 'x-m4m-pagination-items';

@Injectable({ providedIn: 'root' })
export class ApiService {
  private readonly apiBaseUrl!: string;

  constructor(private httpClient: HttpClient, private environmentService: EnvironmentService) {
    this.apiBaseUrl = environmentService.getApiBaseUrl();
  }

  public getAll$<T>(
    endpoint: string,
    queryParameters: QueryParametersInterface = {},
    headers: { [key: string]: string } = {}
  ): Observable<ApiResponse<T>> {
    let apiQueryParameters: ApiQueryParametersInterface = removeNullAndUndefined(queryParameters);
    apiQueryParameters = handleArrayParameters(apiQueryParameters);

    return this.httpClient
      .get<T[]>(`${this.apiBaseUrl}${endpoint}`, {
        params: apiQueryParameters,
        headers: headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<T[]>) => {
          return { totalCount: Number(response.headers.get(TOTAL_COUNT_HEADER_KEY) ?? -1), items: response.body };
        })
      );
  }

  public get$<T>(endpoint: string, queryParameters?: QueryParametersInterface): Observable<T> {
    let apiQueryParameters: ApiQueryParametersInterface = removeNullAndUndefined(queryParameters);
    apiQueryParameters = handleArrayParameters(apiQueryParameters);

    return this.httpClient.get<T>(`${this.apiBaseUrl}${endpoint}`, { params: apiQueryParameters });
  }

  public getFile$(endpoint: string, queryParameters?: QueryParametersInterface): Observable<File> {
    let apiQueryParameters: ApiQueryParametersInterface = removeNullAndUndefined(queryParameters);
    apiQueryParameters = handleArrayParameters(apiQueryParameters);

    return this.httpClient.get<File>(`${this.apiBaseUrl}${endpoint}`, {
      params: apiQueryParameters,
      responseType: 'blob' as 'json',
    });
  }

  public post$<T>(endpoint: string, data: any = null, queryParameters?: QueryParametersInterface): Observable<T> {
    let apiQueryParameters: ApiQueryParametersInterface = removeNullAndUndefined(queryParameters);
    apiQueryParameters = handleArrayParameters(apiQueryParameters);

    return this.httpClient.post<T>(`${this.apiBaseUrl}${endpoint}`, data, { params: apiQueryParameters });
  }

  public put$<T>(endpoint: string, data: any = null): Observable<T> {
    return this.httpClient.put<T>(`${this.apiBaseUrl}${endpoint}`, data);
  }

  public delete$<T>(endpoint: string, data: any = null): Observable<T> {
    return this.httpClient.delete<T>(`${this.apiBaseUrl}${endpoint}`, { body: data });
  }
}
