import { ApiRoleEnum } from '../enums';

export type ApiRoleType =
  | ApiRoleEnum.EducationManager
  | ApiRoleEnum.Administrator
  | ApiRoleEnum.HumanResourceManager
  | ApiRoleEnum.PlanningViewer
  | ApiRoleEnum.PlanningManager
  | ApiRoleEnum.ProjectManager
  | ApiRoleEnum.HoursManager
  | ApiRoleEnum.LeaveRequestManager
  | ApiRoleEnum.InspectionManager
  | ApiRoleEnum.OrderManager
  | ApiRoleEnum.DownloadsManager
  | ApiRoleEnum.CompensationManager
  | ApiRoleEnum.FormManager
  | ApiRoleEnum.CommunicationManager;
