import { ApiUpdateTemporaryPasswordRequest, UpdateTemporaryPasswordRequest } from '../interfaces';

export function mapUpdateTemporaryPasswordRequest(
  request: UpdateTemporaryPasswordRequest
): ApiUpdateTemporaryPasswordRequest {
  return {
    password: request.temporaryPassword,
    new_password: request.newPassword,
  };
}
