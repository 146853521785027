import { StatusType } from '../types';

export interface CalendarItemInterface {
  id: string;
  name: string;
  reference?: string;
  description?: string;
  label?: string;
  compactLabel?: string;
  subItem?: CalendarItemInterface;
  type: StatusType;
  infoIcon?: boolean;
  selected?: boolean;
}
