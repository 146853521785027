import { FilterOptionValueInterface } from '../interfaces';

function mapItem(item: { id: string; name: string }): FilterOptionValueInterface {
  return { value: item.id, label: item.name };
}

function mapProjectItemDescription(branch: string, code: string): string {
  if (branch && code) {
    return `${code} - ${branch.charAt(0).toUpperCase() + branch.slice(1)}`;
  }
  if (branch && !code) {
    return `${branch.charAt(0).toUpperCase() + branch.slice(1)}`;
  }
  if (code && !branch) {
    return `${code}`;
  }
}

function mapProjectItem(item: { id: string; name: string; code: string; branch: string }): FilterOptionValueInterface {
  return {
    value: item.id,
    label: item.name,
    searchableValue: item.code,
    description: mapProjectItemDescription(item.branch, item.code),
    branch: item.branch,
  };
}

export function mapFilter(items: { id: string; name: string }[]): FilterOptionValueInterface[] {
  return items.map(mapItem);
}

export function mapProjectFilter(items: { id: string; name: string }[]): FilterOptionValueInterface[] {
  return items.map(mapProjectItem);
}

export function mapItemFilter(response: { items: { id: string; name: string }[] }): FilterOptionValueInterface[] {
  return response.items.map(mapItem);
}

export function mapSubDisciplineFilter(response: {
  items: { subDiscipline: { id: string; name: string } }[];
}): FilterOptionValueInterface[] {
  return response.items.map((item: { subDiscipline: { id: string; name: string } }) => ({
    value: item.subDiscipline.id,
    label: item.subDiscipline.name,
  }));
}

export function mapLevelsFilter(response: {
  items: { id: string; levelName: string }[];
}): FilterOptionValueInterface[] {
  return response.items.map((level) => ({ value: level.id, label: level.levelName }));
}

export function mapEmployeeFilter(
  employees: { id: string; first_name: string; infix: string; last_name: string }[]
): FilterOptionValueInterface[] {
  return employees.map((employee) => ({
    value: employee.id,
    label: `${employee.first_name} ${employee.infix} ${employee.last_name}`.replace('  ', ' '),
  }));
}

export function mapEmployeeAvailability(
  employees: {
    id: string;
    first_name: string;
    infix: string;
    last_name: string;
    branch: string;
    assignment_count: number;
  }[]
): FilterOptionValueInterface[] {
  return employees.map((employee) => ({
    value: employee.id,
    label: `${employee.first_name} ${employee.infix} ${employee.last_name}`.replace('  ', ' '),
    available: employee.assignment_count === 0,
    branch: employee.branch,
  }));
}
