import { ApiRoleType } from '@nutt/configuration';

export interface ApiUserResponse {
  id: string;
  active: boolean;
  privacy_mode: true;
  plannable: true;
  news_visibility: string;
  agenda_visibility: string;
  icon: string;
  email: string;
  roles: ApiRoleType[];
  first_name: string;
  infix: string;
  last_name: string;
  corporate_email?: string;
  title?: string;
  address: {
    street?: string;
    number?: number;
    addition?: string;
    postal_code?: string;
    city?: string;
  };
  phone_number: string;
  private_number: string;
  date_of_birth: string;
  date_of_employment: string;
  image: string;
  avatar: string | null;
  new_password_required: boolean;
  branch?: string;
}
