import { Inject, Injectable } from '@angular/core';
import { FEATURE_ACCESS } from '../constants';
import { CONFIGURATION } from '../injection-tokens';
import {
  BranchConfigurationInterface,
  ConfigurationInterface,
  FeatureAccessInterface,
  FeatureConfigurationInterface,
  TenantConfigurationInterface,
} from '../interfaces';
import { FeatureType } from '../types';

@Injectable({ providedIn: 'root' })
export class ConfigurationService {
  constructor(@Inject(CONFIGURATION) private configuration: ConfigurationInterface) {}

  public getFeatureAccess(feature: FeatureType): FeatureAccessInterface {
    const foundFeatureAccess = FEATURE_ACCESS.find(
      (featureAccess: FeatureAccessInterface) => featureAccess.feature === feature
    );

    if (!foundFeatureAccess) {
      throw new Error('Feature not configured...');
    }

    return foundFeatureAccess;
  }

  public getTenant(): TenantConfigurationInterface {
    return this.configuration.tenant;
  }

  public getBranches(): BranchConfigurationInterface[] {
    return this.configuration.branches;
  }

  public getFeatures(): FeatureConfigurationInterface[] {
    return this.configuration.features;
  }

  public getFeature(feature: FeatureType): FeatureConfigurationInterface {
    return this.configuration.features.find(
      (featureConfiguration: FeatureConfigurationInterface) => featureConfiguration.feature === feature
    );
  }

  public getMetadata(feature: FeatureType): any {
    return this.getFeature(feature)?.metadata;
  }
}
