import { ApiQueryParametersInterface } from '../interfaces';

export function removeNullAndUndefined(object?: any): ApiQueryParametersInterface {
  Object.keys(object ?? {}).forEach((key) => {
    if (object[key] === undefined || object[key] === null) {
      delete object[key];
    }
  });
  return object;
}

export function handleArrayParameters(object?: { [key: string]: any }): any {
  Object.entries(object ?? {}).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      object[`${key}[]`] = value;
      delete object[key];
    }
  });
  return object;
}
