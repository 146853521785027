import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '../injection-tokens';
import { EnvironmentInterface } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
  constructor(@Inject(ENVIRONMENT) private environment: EnvironmentInterface) {}

  public getClientId(): string {
    return this.environment.clientId;
  }

  public isProduction(): boolean {
    return this.environment.isProduction;
  }

  /** @return string the base url ending on '/' */
  public getApiBaseUrl(): string {
    return this.environment.apiBaseUrl;
  }

  /** @return string the base url ending on '/' */
  public getAvatarBaseUrl(): string {
    return this.environment.avatarBaseUrl;
  }
}
