export const enum AssignmentLeaveTypeEnum {
  School = 'School',
  Sick = 'Ziek',
  Leave = 'Verlof',
  Doctor = 'Arts',
  ParentLeave = 'Ouderschapsverlof',
  ReducedWorkingHours = 'ATV',
  Other = 'Anders',
  UnScheduled = 'Roostervrij',
}
