import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService, AuthorizationService } from '../services';
import { FeatureType, RoutesEnum } from '@nutt/configuration';

@Injectable({
  providedIn: 'root',
})
export class AuthorizedGuard implements CanActivate {
  constructor(
    private router: Router,
    private authorizationService: AuthorizationService,
    private authenticationService: AuthenticationService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const feature: FeatureType | null = route.data.feature;

    if (!feature) {
      throw new Error('Feature not provided for authorization guard');
    }

    const canAccessFeature: boolean = this.authorizationService.canAccessFeature(feature);

    if (this.authenticationService.getUser().temporaryPassword) {
      this.router.navigate(['/', RoutesEnum.UpdateTemporaryPassword]);
      return false;
    }

    if (!canAccessFeature) {
      this.router.navigate(['/', RoutesEnum.Dashboard]);
    }
    return canAccessFeature;
  }
}
