import { Injectable } from '@angular/core';
import { DataRequest } from '../interfaces';
import { RequestStateStore } from '../store';

const VIEW_SUFFIX = '_view';

@Injectable({ providedIn: 'root' })
export class RequestStateService {
  constructor(private requestStateStore: RequestStateStore) {}

  public getRequest<T>(key: string): DataRequest<T> | null {
    return this.requestStateStore.get<DataRequest<T>>(key);
  }

  public setRequest<T>(key: string, request: DataRequest<T>): void {
    this.requestStateStore.set<DataRequest<T>>(key, { ...request, search: undefined });
  }

  public getShowWeekend<T>(key: string): boolean {
    return !!this.requestStateStore.get<boolean>(key + VIEW_SUFFIX);
  }

  public setShowWeekend<T>(key: string, showWeekend: boolean): void {
    this.requestStateStore.set<boolean>(key + VIEW_SUFFIX, showWeekend);
  }
}
