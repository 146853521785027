import { inject, Pipe, PipeTransform } from '@angular/core';
import { ConfigurationService } from '@nutt/configuration';

@Pipe({
  name: 'formatBranch',
  standalone: true,
})
export class BranchPipe implements PipeTransform {
  private readonly configurationService = inject(ConfigurationService);

  public transform(branchCode: string): string {
    return this.configurationService.getBranches().find((branch) => branch.value === branchCode)?.name ?? '';
  }
}
