import { RoleEnum } from '../enums';

export type RoleType =
  | RoleEnum.EducationManager
  | RoleEnum.Administrator
  | RoleEnum.HumanResourceManager
  | RoleEnum.PlanningViewer
  | RoleEnum.PlanningManager
  | RoleEnum.ProjectManager
  | RoleEnum.HoursManager
  | RoleEnum.LeaveRequestManager
  | RoleEnum.InspectionManager
  | RoleEnum.OrderManager
  | RoleEnum.DownloadsManager
  | RoleEnum.CompensationManager
  | RoleEnum.FormManager
  | RoleEnum.CommunicationManager;
