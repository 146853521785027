import { PaginationInterface, PaginationStateInterface } from '../interfaces';

export function mapPagination(
  totalNumberOfItems: number,
  numberOfItems: number,
  pagination: PaginationInterface
): PaginationStateInterface {
  let numberOfPages = -1;
  // For webservices that do not support pagination fully
  if (totalNumberOfItems > -1) {
    numberOfPages =
      totalNumberOfItems < pagination.limit
        ? 1
        : totalNumberOfItems % pagination.limit === 0
        ? totalNumberOfItems / pagination.limit
        : Math.floor(totalNumberOfItems / pagination.limit) + 1;
  }

  return {
    ...pagination,
    totalNumberOfItems,
    numberOfItems,
    numberOfPages,
    page: pagination.offset / pagination.limit + 1,
  };
}
