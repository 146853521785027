import {
  ApiLoginRequest,
  ApiMobileLoginRequest,
  ApiRefreshTokenRequest,
  ApiSessionResponse,
  LoginRequest,
  MobileLoginRequest,
  RefreshTokenRequest,
  SessionInterface,
} from '../interfaces';

export function mapLoginRequest(request: LoginRequest, clientId: string): ApiLoginRequest {
  return {
    username: request.email,
    password: request.password,
    grant_type: 'password',
    client_id: clientId,
  };
}

export function mapSessionResponse(response: ApiSessionResponse): SessionInterface {
  return {
    type: response.token_type,
    expiresInSeconds: response.expires_in,
    accessToken: response.access_token,
    refreshToken: response.refresh_token,
  };
}

export function mapRefreshTokenRequest(request: RefreshTokenRequest, clientId): ApiRefreshTokenRequest {
  return {
    refresh_token: request.refreshToken,
    grant_type: 'refresh_token',
    client_id: clientId,
  };
}

export function mapMobileLoginRequest(request: MobileLoginRequest, clientId): ApiMobileLoginRequest {
  return {
    token: request.externalToken,
    grant_type: 'external_token',
    client_id: clientId,
  };
}
