import { Injectable } from '@angular/core';
import { SessionInterface, UserInterface } from '../interfaces';

const SESSION_KEY = 'session';
const USER_KEY = 'user';

@Injectable({ providedIn: 'root' })
export class AuthenticationStore {
  public hasSession(): boolean {
    return localStorage.getItem(SESSION_KEY) !== null;
  }

  public storeSession(session: SessionInterface): void {
    localStorage.setItem(SESSION_KEY, JSON.stringify(session));
  }

  public removeSession(): void {
    localStorage.removeItem(SESSION_KEY);
  }

  public getSession(): SessionInterface {
    const session = localStorage.getItem(SESSION_KEY);

    if (session === null) {
      throw new Error('Session not found');
    }

    return <SessionInterface>JSON.parse(session);
  }

  public storeUser(user: UserInterface): void {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public removeUser(): void {
    localStorage.removeItem(USER_KEY);
  }

  public getUser(): UserInterface {
    const user = localStorage.getItem(USER_KEY);

    if (user === null) {
      throw new Error('User not found');
    }

    return <UserInterface>JSON.parse(user);
  }
}
