export function mapApiIcon(icon: string): string {
  switch (icon) {
    case 'hammer':
      return 'hardware';
    case 'suitcase':
      return 'work';
    case 'lightbulb':
      return 'lightbulb';
    case 'window':
      return 'window';
    case 'flame':
      return 'local_fire_department';
    case 'headset':
      return 'headset_mic';
    case 'cone':
      return 'radar';
    case 'wrench':
      return 'tools_wrench';
    case 'helmet':
      return 'engineering';
    case 'tie':
      return 'sell';
    case 'compass':
      return 'architecture';
    case 'paint_roller':
      return 'imagesearch_roller';
    case 'lightning':
      return 'bolt';
    case 'storage':
      return 'filter_none';
    case 'water':
      return 'water_drop';
    case 'calculator':
      return 'calculate';
    case 'note_pencil':
      return 'edit_note';
    case 'hardware':
      return 'construction';
    case 'default':
      return 'person';
    default:
      return 'unknown_document';
  }
}
