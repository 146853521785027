import { FeatureEnum } from '../enums';

export type FeatureType =
  | FeatureEnum.Advanced
  | FeatureEnum.Agenda
  | FeatureEnum.Compensation
  | FeatureEnum.Dashboard
  | FeatureEnum.Downloads
  | FeatureEnum.Education
  | FeatureEnum.Employees
  | FeatureEnum.Forms
  | FeatureEnum.Hours
  | FeatureEnum.Inspections
  | FeatureEnum.Leave
  | FeatureEnum.NarrowCasting
  | FeatureEnum.News
  | FeatureEnum.Orders
  | FeatureEnum.Planning
  | FeatureEnum.Projects;
