export function getExtension(mimeType: string): string {
  // Doesn't change default behaviour, this is just for overrides where mimetype doesn't match extension
  switch (mimeType) {
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return '.xlsx';
    default:
      const mimeTypes = mimeType.split('/');
      return mimeTypes[mimeTypes.length - 1];
  }
}

export function createFileName(title: string, mimeType: string): string {
  const extension = getExtension(mimeType);
  return `${title}.${extension}`;
}
