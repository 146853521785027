import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.authenticationService.hasSession()) {
      return next.handle(request);
    }
    if (request.url.indexOf('narrowcasting') > -1) {
      return next.handle(request);
    }
    const session = this.authenticationService.getSession();
    request = request.clone({ setHeaders: { Authorization: `Bearer ${session.accessToken}` } });
    return next.handle(request);
  }
}
