export const enum RoleEnum {
  Administrator = 'Admin',
  CommunicationManager = 'Communicatiebeheerder (Agenda, Nieuws)',
  CompensationManager = 'Vergoedingen verwerken',
  DownloadsManager = 'Downloads beheren',
  EducationManager = 'Academie beheerder',
  FormManager = 'Formulieren beheren',
  HoursManager = 'Urenlijsten verwerken',
  HumanResourceManager = 'HRM',
  InspectionManager = 'Inspecties verwerken',
  LeaveRequestManager = 'Verlofaanvragen verwerken',
  OrderManager = 'Bestellingen verwerken',
  PlanningManager = 'Planning beheren',
  PlanningViewer = 'Planning bekijken',
  ProjectManager = 'Projectleider',
}
