import { ModuleWithProviders, NgModule } from '@angular/core';
import { CONFIGURATION, ENVIRONMENT } from './injection-tokens';
import { ConfigurationInterface, EnvironmentInterface } from './interfaces';

@NgModule({})
export class ConfigurationModule {
  public static configure(
    environment: EnvironmentInterface,
    configuration: ConfigurationInterface
  ): ModuleWithProviders<ConfigurationModule> {
    return {
      ngModule: ConfigurationModule,
      providers: [
        { provide: ENVIRONMENT, useValue: environment },
        { provide: CONFIGURATION, useValue: configuration },
      ],
    };
  }
}
