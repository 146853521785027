import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  ApiLoginRequest,
  ApiMobileLoginRequest,
  ApiRefreshTokenRequest,
  ApiResetPasswordRequest,
  ApiSessionRequest,
  ApiSessionResponse,
  ApiUpdateTemporaryPasswordRequest,
  ApiUserResponse,
  LoginRequest,
  MobileLoginRequest,
  RefreshTokenRequest,
  ResetPasswordRequest,
  SessionInterface,
  UpdateTemporaryPasswordRequest,
  UserInterface,
} from '../interfaces';
import {
  mapLoginRequest,
  mapMobileLoginRequest,
  mapRefreshTokenRequest,
  mapResetPasswordRequest,
  mapSessionResponse,
  mapUpdateTemporaryPasswordRequest,
  mapUserResponse,
} from '../mappers';
import { AuthenticationStore } from '../store';
import { ApiService } from './api.service';
import { EnvironmentService } from '@nutt/configuration';

const SESSION_ENDPOINT = 'token';
const ME_ENDPOINT = 'me';
const FORGOT_PASSWORD_ENDPOINT = 'reset-password';
const RESET_PASSWORD_ENDPOINT = 'do-reset-password';
const UPDATE_PASSWORD_ENDPOINT = 'password';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private session?: SessionInterface;
  private user?: UserInterface;
  private user$?: BehaviorSubject<UserInterface>;
  private isRefreshing: boolean = false;
  private temporaryPassword?: string;

  constructor(
    private apiService: ApiService,
    private environmentService: EnvironmentService,
    private authenticationStore: AuthenticationStore
  ) {
    if (authenticationStore.hasSession()) {
      this.session = authenticationStore.getSession();
      this.user = authenticationStore.getUser();
      this.user$ = new BehaviorSubject(this.user);
    }
  }

  public isLoggedIn(): boolean {
    return this.hasSession() && !this.user?.temporaryPassword;
  }

  public hasSession(): boolean {
    return !!this.session;
  }

  public getUser(): UserInterface {
    return this.user;
  }

  public getUser$(): Observable<UserInterface> {
    return this.user$.asObservable();
  }

  public getSession(): SessionInterface {
    return this.session;
  }

  public login$(request: LoginRequest): Observable<UserInterface> {
    const clientId = this.environmentService.getClientId();
    const apiRequest: ApiLoginRequest = mapLoginRequest(request, clientId);

    return this.session$(apiRequest);
  }

  public mobileLogin$(request: MobileLoginRequest): Observable<UserInterface> {
    const clientId = this.environmentService.getClientId();
    const apiRequest: ApiMobileLoginRequest = mapMobileLoginRequest(request, clientId);

    return this.session$(apiRequest);
  }

  public refreshSession$(): Observable<UserInterface> {
    if (this.isRefreshing) {
      throw new Error('Already refreshing...');
    }
    this.isRefreshing = true;
    const request: RefreshTokenRequest = { refreshToken: this.session.refreshToken };
    const clientId = this.environmentService.getClientId();
    const apiRequest: ApiRefreshTokenRequest = mapRefreshTokenRequest(request, clientId);

    return this.session$(apiRequest).pipe(tap(() => (this.isRefreshing = false)));
  }

  private session$(request: ApiSessionRequest): Observable<UserInterface> {
    return this.apiService.post$<ApiSessionResponse>(SESSION_ENDPOINT, request).pipe(
      map(mapSessionResponse),
      map((session: SessionInterface) => this.handleSession(session)),
      switchMap(() => this.getUserFromApi$().pipe(catchError((error) => throwError(() => error)))),
      tap((user: UserInterface) => this.handleUser(user)),
      catchError((error: unknown) => {
        this.onLogout();
        return throwError(() => error);
      })
    );
  }

  private getUserFromApi$(): Observable<UserInterface> {
    return this.apiService
      .get$<ApiUserResponse>(ME_ENDPOINT)
      .pipe(map((user: ApiUserResponse) => mapUserResponse(user, this.environmentService.getAvatarBaseUrl())));
  }

  public logout$(): Observable<void> {
    this.onLogout();

    // TODO : Do api call with refresh token to invalidate (BE)
    return of(null);
  }

  public forgotPassword$(username: string): Observable<void> {
    const endpoint = `${ME_ENDPOINT}/${FORGOT_PASSWORD_ENDPOINT}`;

    return this.apiService.post$<void>(endpoint, { username });
  }

  public resetPassword$(request: ResetPasswordRequest): Observable<void> {
    const endpoint = `${ME_ENDPOINT}/${RESET_PASSWORD_ENDPOINT}`;
    const apiRequest: ApiResetPasswordRequest = mapResetPasswordRequest(request);

    return this.apiService.post$<void>(endpoint, apiRequest);
  }

  public updateTemporaryPassword$(request: UpdateTemporaryPasswordRequest): Observable<void> {
    const endpoint = `${ME_ENDPOINT}/${UPDATE_PASSWORD_ENDPOINT}`;
    const apiRequest: ApiUpdateTemporaryPasswordRequest = mapUpdateTemporaryPasswordRequest(request);

    return this.apiService.put$<void>(endpoint, apiRequest).pipe(
      tap(() => {
        this.user.temporaryPassword = false;
        this.user$.next(this.user);
        this.authenticationStore.storeUser(this.user);
      })
    );
  }

  public getTemporaryPassword(): string | null {
    return this.temporaryPassword ?? null;
  }

  public setTemporaryPassword(temporaryPassword: string): void {
    this.temporaryPassword = temporaryPassword;
  }

  private onLogout(): void {
    this.session = undefined;
    this.user = undefined;
    this.authenticationStore.removeSession();
    this.authenticationStore.removeUser();
  }

  private handleSession(session: SessionInterface): void {
    this.session = session;
    this.authenticationStore.storeSession(session);
  }

  private handleUser(user: UserInterface): void {
    this.user = user;
    if (!this.user$) {
      this.user$ = new BehaviorSubject(user);
    }
    this.user$.next(user);
    this.authenticationStore.storeUser(user);
  }
}
