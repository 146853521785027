export const enum EducationFeatureNameEnum {
  Dashboard = 'Dashboard',

  // List views
  Courses = 'Opleidingsaanbod',
  Requests = 'Aanvragen',
  Enrollments = 'Inschrijvingen',
  LicenseStatus = 'Certificeringen',

  // Booking flow
  Course = 'Opleiding',
  SelectBookingOption = 'Opleiding boeken',
  SelectParticipants = 'Medewerkers selecteren',
  SelectInvoiceAddress = 'Factuuradres selecteren',
  ConfirmBooking = 'Boeking bevestigen',

  // Job Profiles
  JobProfiles = 'Functieprofielen',
  JobProfile = 'Functieprofiel',
}
