import { EducationFeatureNameEnum, FeatureNameEnum } from '../features';
import { NavigationItemInterface } from '../interfaces';
import { EducationRoutesEnum, RoutesEnum } from '../routes';

export const educationNavigationItem: Readonly<NavigationItemInterface> = {
  name: FeatureNameEnum.Education,
  route: RoutesEnum.Education,
  icon: 'school',
  subNavigationItems: [
    // TODO : Enable when implemented
    // { name: 'Dashboard', route: `${RoutesEnum.Education}/${EducationRoutesEnum.Dashboard}` },
    { name: EducationFeatureNameEnum.Courses, route: `${RoutesEnum.Education}/${EducationRoutesEnum.Courses}` },
    { name: EducationFeatureNameEnum.Requests, route: `${RoutesEnum.Education}/${EducationRoutesEnum.Requests}` },
    {
      name: EducationFeatureNameEnum.Enrollments,
      route: `${RoutesEnum.Education}/${EducationRoutesEnum.Enrollments}`,
    },
    {
      name: EducationFeatureNameEnum.LicenseStatus,
      route: `${RoutesEnum.Education}/${EducationRoutesEnum.LicenseStatus}`,
    },
    {
      name: EducationFeatureNameEnum.JobProfiles,
      route: `${RoutesEnum.Education}/${EducationRoutesEnum.JobProfiles}`,
    },
  ],
};
