import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import {
  ConfigurationService,
  FeatureConfigurationInterface,
  FeatureType,
  RoleEnum,
  RoleType,
} from '@nutt/configuration';

@Injectable({ providedIn: 'root' })
export class AuthorizationService {
  constructor(
    private authenticationService: AuthenticationService,
    private configurationService: ConfigurationService
  ) {}

  public canAccessFeature(feature: FeatureType): boolean {
    if (!this.isFeatureEnabled(feature)) {
      return false;
    }

    const featureRoles: RoleType[] = this.configurationService.getFeatureAccess(feature).roles;
    // If no roles are specified, everybody has access
    if (featureRoles.length === 0) {
      return true;
    }

    const userRoles: RoleType[] = this.authenticationService.getUser().roles;
    // Administrators have access to everything
    if (userRoles.includes(RoleEnum.Administrator)) {
      return true;
    }

    // If specified, user should have at least one of the roles that has access
    const validRoles = featureRoles.filter((role: RoleType) => userRoles.includes(role));

    return validRoles.length > 0;
  }

  public isFeatureEnabled(feature: FeatureType): boolean {
    const features = this.configurationService.getFeatures();
    const featureConfiguration = features.find(
      (featureConfiguration: FeatureConfigurationInterface) => featureConfiguration.feature === feature
    );
    return !featureConfiguration?.isDisabled;
  }

  public isFeatureFlagEnabled(feature: FeatureType, flag: string): boolean {
    const features = this.configurationService.getFeatures();
    const featureConfiguration = features.find(
      (configuration: FeatureConfigurationInterface) => configuration.feature === feature
    );
    if (!featureConfiguration) {
      return false;
    }
    return !!featureConfiguration.flags?.[flag];
  }
}
