import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services';
import { RoutesEnum } from '@nutt/configuration';

@Injectable({
  providedIn: 'root',
})
export class UnauthenticatedGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isLoggedIn = this.authenticationService.isLoggedIn();

    if (isLoggedIn) {
      this.router.navigate(['/', RoutesEnum.Dashboard]);
    }
    return !isLoggedIn;
  }
}
