import { RoleType } from '@nutt/configuration';

export interface UserInterface {
  id: string;
  firstName: string;
  infix: string;
  lastName: string;
  fullName: string;
  temporaryPassword: boolean;
  avatarUrl?: string;
  roles: RoleType[];
  branch: string;
}
