export const enum ApiRoleEnum {
  Administrator = 'ROLE_ADMIN',
  CommunicationManager = 'ROLE_COMMUNICATION_MANAGER',
  CompensationManager = 'ROLE_COMPENSATION_MANAGER',
  DownloadsManager = 'ROLE_FILE_MANAGER',
  EducationManager = 'ROLE_TOP_MANAGER',
  FormManager = 'ROLE_FORM_MANAGER',
  HoursManager = 'ROLE_WORKLOG_MANAGER',
  HumanResourceManager = 'ROLE_HRM',
  InspectionManager = 'ROLE_INSPECTION_MANAGER',
  LeaveRequestManager = 'ROLE_LEAVE_REQUEST_MANAGER',
  OrderManager = 'ROLE_ORDER_MANAGER',
  PlanningManager = 'ROLE_PLANNER',
  PlanningViewer = 'ROLE_ASSIGNMENTS_READ_ONLY',
  ProjectManager = 'ROLE_PROJECT_LEADER',
}
