export const enum EducationRoutesEnum {
  Dashboard = 'dashboard',

  // Overview Pages
  Courses = 'opleidingen',
  Requests = 'aanvragen',
  Enrollments = 'inschrijvingen',
  Certifications = 'certificeringen',

  // Booking Flow
  SelectBookingOption = 'startmoment-kiezen',
  SelectParticipants = 'medewerkers-selecteren',
  SelectInvoiceAddress = 'factuuradres',
  ConfirmBooking = 'boeking-bevestigen',

  // Job Profiles
  JobProfiles = 'functieprofielen',
  LicenseStatus = 'certificeringen',
}
