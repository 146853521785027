export const enum FeatureEnum {
  Agenda = 'Agenda',
  Compensation = 'Compensation',
  Dashboard = 'Dashboard',
  Downloads = 'Downloads',
  Education = 'Education',
  Forms = 'Forms',
  Hours = 'Hours',
  Inspections = 'Inspections',
  Leave = 'Leave',
  News = 'News',
  Orders = 'Orders',
  Planning = 'Planning',
  NarrowCasting = 'NarrowCasting', // Planning Dashboard (pin-protected public dashboard)
  Projects = 'Projects',
  Advanced = 'Advanced',
  Employees = 'Employees',
}
