import { Injectable } from '@angular/core';

const REQUEST_PREFIX = 'request-state';

@Injectable({ providedIn: 'root' })
export class RequestStateStore {
  public get<T>(key: string): T | null {
    key = this.formatKey(key);
    const json = localStorage.getItem(key);

    if (!json) {
      return null;
    }

    return JSON.parse(json);
  }

  public set<T>(key: string, object: T): void {
    key = this.formatKey(key);
    const json = JSON.stringify(object);

    localStorage.setItem(key, json);
  }

  private formatKey(key: string): string {
    return `${REQUEST_PREFIX}_${key}`;
  }
}
