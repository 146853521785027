import { ApiRequestStatusEnum, RequestStatusEnum } from '../enums';
import { ApiRequestStatusType, RequestStatusType } from '../types';

export function mapRequestStatus(status: RequestStatusType): ApiRequestStatusType {
  switch (status) {
    case RequestStatusEnum.Denied:
      return ApiRequestStatusEnum.Denied;
    case RequestStatusEnum.Granted:
      return ApiRequestStatusEnum.Granted;
    case RequestStatusEnum.Pending:
      return ApiRequestStatusEnum.Pending;
    default:
      throw new Error('RequestStatus not supported: ' + status);
  }
}

export function mapApiRequestStatus(apiStatus: ApiRequestStatusType): RequestStatusType {
  switch (apiStatus) {
    case ApiRequestStatusEnum.Pending:
      return RequestStatusEnum.Pending;
    case ApiRequestStatusEnum.Granted:
      return RequestStatusEnum.Granted;
    case ApiRequestStatusEnum.Denied:
      return RequestStatusEnum.Denied;
    default:
      throw new Error('ApiRequestStatus not supported: ' + apiStatus);
  }
}
