import { ApiStatisticsResponse, StatisticsInterface } from '../interfaces';

export function mapStatisticsResponse(response: ApiStatisticsResponse): StatisticsInterface {
  return {
    numberOfActiveEmployees: response.number_of_active_employees,
    numberOfActiveProjects: response.number_of_active_projects,
    numberOfMinutesBookedThisWeek: response.number_of_minutes_booked_this_week,
    numberOfNewsItemsPublishedThisYear: response.number_of_news_items_this_year,
    numberOfChangedEmployees: response.number_of_dirty_employees,
    numberOfPendingForms: response.number_of_pending_form_instances,
    numberOfOpenOrders: response.number_of_open_orders,
    numberOfPendingCompensationRequests: response.number_of_pending_compensation_requests,
    numberOfPendingLeaveRequests: response.number_of_pending_leave_requests,
  };
}
