import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiStatisticsResponse, StatisticsInterface } from '../interfaces';
import { mapStatisticsResponse } from '../mappers';
import { ApiService } from './api.service';

const STATISTICS_ENDPOINT = 'dashboard-statistics';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {
  constructor(private apiService: ApiService) {}

  public getStatistics$(): Observable<StatisticsInterface> {
    return this.apiService.get$<ApiStatisticsResponse>(STATISTICS_ENDPOINT).pipe(map(mapStatisticsResponse));
  }
}
