import { AssignmentLeaveTypeEnum, ConfigurationInterface, FeatureEnum, FeatureFlagEnum } from '@nutt/configuration';

export const configuration: Readonly<ConfigurationInterface> = {
  tenant: { brand: 'Vennegoor', logoUrl: 'assets/logo.svg' },

  branches: [
    { name: 'Vennegoor', value: 'WRS' },
    { name: 'VEM', value: 'VEM' },
    { name: 'Hampsink', value: 'HAM' },
    { name: 'GeJo', value: 'GEJ' },
    { name: 'Service', value: 'SRV' },
    { name: 'Intern', value: 'INT' },
  ],

  features: [
    // The form feature is never properly tested beyond its pilot phase, so it is disabled for now.
    { feature: FeatureEnum.Forms, isDisabled: true },
    { feature: FeatureEnum.Compensation, isDisabled: true },
    {
      feature: FeatureEnum.Employees,
      flags: {
        [FeatureFlagEnum.LinkedWithLoket]: true,
        [FeatureFlagEnum.ChildrenField]: true,
        [FeatureFlagEnum.LeaveBalance]: true,
      },
    },
    { feature: FeatureEnum.Inspections, isDisabled: true },
    {
      feature: FeatureEnum.Planning,
      flags: {
        [FeatureFlagEnum.Viewer]: true,
        [FeatureFlagEnum.DefaultWholeDay]: true,
        [FeatureFlagEnum.CanTransferEmployees]: true,
      },
      metadata: {
        assignableLeaveTypes: [
          AssignmentLeaveTypeEnum.School,
          AssignmentLeaveTypeEnum.Sick,
          AssignmentLeaveTypeEnum.Leave,
          AssignmentLeaveTypeEnum.Doctor,
          AssignmentLeaveTypeEnum.ParentLeave,
        ],
        defaultStartOfWorkDay: '07:30',
        defaultEndOfWorkDay: '16:30',
      },
    },
    { feature: FeatureEnum.Education, isDisabled: true },
  ],
};
