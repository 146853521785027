import { FeatureEnum, RoleEnum } from '../enums';
import { FeatureAccessInterface } from '../interfaces';

// RoleEnum.Administrator has access to everything
// If roles is empty, everybody has access
export const FEATURE_ACCESS: Readonly<FeatureAccessInterface[]> = [
  { feature: FeatureEnum.Agenda, roles: [RoleEnum.HumanResourceManager, RoleEnum.CommunicationManager] },
  { feature: FeatureEnum.Compensation, roles: [RoleEnum.CompensationManager] },
  { feature: FeatureEnum.Dashboard, roles: [] },
  { feature: FeatureEnum.Downloads, roles: [RoleEnum.DownloadsManager] },
  { feature: FeatureEnum.Education, roles: [RoleEnum.EducationManager] },
  { feature: FeatureEnum.Employees, roles: [RoleEnum.HumanResourceManager] },
  { feature: FeatureEnum.Forms, roles: [RoleEnum.FormManager] },
  { feature: FeatureEnum.Hours, roles: [RoleEnum.HoursManager, RoleEnum.HumanResourceManager] },
  { feature: FeatureEnum.Inspections, roles: [RoleEnum.InspectionManager] },
  { feature: FeatureEnum.Leave, roles: [RoleEnum.LeaveRequestManager, RoleEnum.HumanResourceManager] },
  { feature: FeatureEnum.News, roles: [RoleEnum.CommunicationManager, RoleEnum.HumanResourceManager] },
  { feature: FeatureEnum.Orders, roles: [RoleEnum.OrderManager] },
  { feature: FeatureEnum.Planning, roles: [RoleEnum.PlanningManager, RoleEnum.PlanningViewer] },
  { feature: FeatureEnum.Projects, roles: [RoleEnum.ProjectManager] },
  { feature: FeatureEnum.Advanced, roles: [RoleEnum.HumanResourceManager] },
];
