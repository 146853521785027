export interface StatisticsInterface {
  // Non-Actionable
  numberOfActiveEmployees: number;
  numberOfActiveProjects: number;
  numberOfMinutesBookedThisWeek: number;
  numberOfNewsItemsPublishedThisYear: number;

  // Actionable
  numberOfChangedEmployees: number;
  numberOfPendingForms: number;
  numberOfOpenOrders: number;
  numberOfPendingCompensationRequests: number;
  numberOfPendingLeaveRequests: number;
}
