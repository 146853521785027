import { addMinutes, format } from 'date-fns';

const PATTERN = '.000Z';

// We just remove the ending of the iso string and replace it with hardcoded timezone offset (no DST support atm)
export function formatIsoDate(date: Date, noOffset: boolean = false): string {
  if (!noOffset) {
    date = addMinutes(date, -date.getTimezoneOffset());
  }
  const isoString = date.toISOString().slice(0, -PATTERN.length);

  return `${isoString}+00:00`;
}

export function formatDate(date: Date): string {
  return format(date, 'yyyy-MM-dd');
}

export function getTime(date: Date): string {
  date = addMinutes(date, -date.getTimezoneOffset());
  const numberOfHours = date.getUTCHours();
  const hours = numberOfHours > 9 ? `${numberOfHours}` : `0${numberOfHours}`;

  const numberOfMinutes = date.getUTCMinutes();
  const minutes = numberOfMinutes > 9 ? `${numberOfMinutes}` : `0${numberOfMinutes}`;

  return `${hours}:${minutes}`;
}

export function addWorkDays(date: Date, amountOfDays: number): Date {
  let workDaysAdded = 0;
  const tempDate = new Date(date.valueOf());
  while (workDaysAdded < amountOfDays) {
    tempDate.setDate(tempDate.getDate() + 1);
    if (tempDate.getDay() !== 0 && tempDate.getDay() !== 6) {
      workDaysAdded++;
    }
  }

  return tempDate;
}
