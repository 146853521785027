import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({ template: '' })
export abstract class DestroyableComponent implements OnDestroy {
  public destroyNotifier$ = new Subject();

  public ngOnDestroy(): void {
    this.destroyNotifier$.next(true);
  }
}
