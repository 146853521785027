import { DayEnum } from '../enums';

export type DayType =
  | DayEnum.Monday
  | DayEnum.Tuesday
  | DayEnum.Wednesday
  | DayEnum.Thursday
  | DayEnum.Friday
  | DayEnum.Saturday
  | DayEnum.Sunday;
