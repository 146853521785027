import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../services';
import { RoutesEnum } from '@nutt/configuration';

const REFRESH_ENDPOINT = '/token';
const USER_ENDPOINT = '/me';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse): Observable<HttpEvent<any>> => {
        // If not logged in, don't do anything
        if (!this.authenticationService.isLoggedIn()) {
          return throwError(() => error);
        }

        // Anything but Unauthorized (401) we don't take action
        if (error.status !== HttpStatusCode.Unauthorized) {
          return throwError(() => error.error);
        }

        if (!request.url.endsWith(REFRESH_ENDPOINT)) {
          return this.authenticationService.refreshSession$().pipe(
            catchError((error: unknown) => {
              this.router.navigate(['/', RoutesEnum.Login]);
              return throwError(() => error);
            }),
            switchMap(() => {
              const session = this.authenticationService.getSession();
              const requestWithNewSession = request.clone({
                setHeaders: { Authorization: `Bearer ${session.accessToken}` },
              });
              return next.handle(requestWithNewSession).pipe(catchError((error: unknown) => throwError(() => error)));
            })
          );
        }

        // Navigate back to login if not refreshable
        this.router.navigate(['/', RoutesEnum.Login]);
        return throwError(() => error);
      })
    );
  }
}
