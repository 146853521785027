import { FilterKeyEnum } from '../enums';

export type FilterKeyType =
  | FilterKeyEnum.BranchCode
  | FilterKeyEnum.ProviderId
  | FilterKeyEnum.CourseId
  | FilterKeyEnum.EmployeeId
  | FilterKeyEnum.PlannableEmployeeId
  | FilterKeyEnum.ManagerId
  | FilterKeyEnum.ProjectId
  | FilterKeyEnum.SubDisciplineId
  | FilterKeyEnum.DisciplineId
  | FilterKeyEnum.RequestStatus
  | FilterKeyEnum.EventType
  | FilterKeyEnum.MaritalStatus
  | FilterKeyEnum.LevelId
  | FilterKeyEnum.ProjectLeadId
  | FilterKeyEnum.Role
  | FilterKeyEnum.InspectionStatus
  | FilterKeyEnum.ProjectType
  | FilterKeyEnum.Licenses;
