import { StatusType } from '../types';

export interface ColumnInterface<T> {
  key: Extract<keyof T, string>;
  name: string;
  hasHighlight?: boolean; // Highlight the cells of this column
  hasAdditionalIcon?: boolean; // True if the column has an icon additional to the text
  format?: (row: T) => string;
  status?: (row: T) => StatusType | undefined;
  statusAction?: (row: T) => void;
  statusIcon?: (row: T) => string | undefined;
  icon?: (row: T) => string;
  tooltip?: (row: T) => string;
}
