import { CalendarItemInterface } from './calendar-item.interface';

export interface CalendarRowInterface {
  icon: string;
  name: string;
  branch: string;
  groupId?: string;
  group?: string;
  isStriped?: boolean;
  isLocked?: boolean;
  isSelected?: boolean;
  isLent?: boolean;
  items: { [key: string]: CalendarItemInterface[] }; // format: '2022-12-31'
}
