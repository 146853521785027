export interface ApiStatisticsResponse {
  // Non-Actionable
  number_of_active_employees: number;
  number_of_active_projects: number;
  number_of_minutes_booked_this_week: number;
  number_of_news_items_this_year: number;

  // Actionable
  number_of_dirty_employees: number;
  number_of_pending_form_instances: number;
  number_of_open_orders: number;
  number_of_pending_compensation_requests: number;
  number_of_pending_leave_requests: number;
}
