import { ApiUserResponse, UserInterface } from '../interfaces';
import { mapApiRole } from '@nutt/configuration';

export function mapUserResponse(response: ApiUserResponse, avatarBaseUrl: string): UserInterface {
  const user: UserInterface = {
    id: response.id,
    firstName: response.first_name,
    infix: response.infix,
    lastName: response.last_name,
    fullName: `${response.first_name} ${response.infix} ${response.last_name}`.replace('  ', ' '),
    temporaryPassword: !!response.new_password_required,
    roles: response.roles.map(mapApiRole),
    branch: response.branch,
  };

  if (response.avatar) {
    user.avatarUrl = avatarBaseUrl + response.avatar + `?date=${new Date().toISOString()}`;
  }

  return user;
}
