import { SearchType } from '../types';
import { FilterOptionInterface } from './filter-option.interface';
import { FilterInterface } from './filter.interface';
import { PaginationStateInterface } from './pagination-state.interface';
import { SearchInterface } from './search.interface';
import { SortInterface } from './sort.interface';

export interface DataResponse<T> {
  items: T[];

  // Request options
  filterableFields: FilterOptionInterface<T>[];
  searchableFields: (Extract<keyof T, string> | SearchType)[];
  sortableFields: Extract<keyof T, string>[];

  // Response state
  pagination: PaginationStateInterface;
  sort?: SortInterface<T>;
  filters?: FilterInterface<T>[];
  search?: SearchInterface<T>;
}
