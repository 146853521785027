export const enum FeatureNameEnum {
  Advanced = 'Geavanceerd',
  Agenda = 'Agenda',
  Compensation = 'Vergoedingen',
  Dashboard = 'Dashboard',
  Downloads = 'Downloads',
  Education = 'Academie',
  Employees = 'Medewerkers',
  Forms = 'Formulieren',
  Hours = 'Urenlijsten',
  Inspections = 'WPI',
  Leave = 'Verlofaanvragen',
  NarrowCasting = 'Viewer',
  News = 'Nieuws',
  Orders = 'Bestellingen',
  Planning = 'Planning',
  Projects = 'Projecten',
  PlanningViewer = 'Planningsoverzicht',
}
