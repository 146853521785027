import { ApiVisibilityEnum, VisibilityEnum } from '../enums';
import { ApiVisibilityType, VisibilityType } from '../types';

export function mapVisibility(visibility: VisibilityType): ApiVisibilityType {
  switch (visibility) {
    case VisibilityEnum.All:
      return ApiVisibilityEnum.All;
    case VisibilityEnum.None:
      return ApiVisibilityEnum.None;
    case VisibilityEnum.BranchOnly:
      return ApiVisibilityEnum.BranchOnly;
  }
}

export function mapApiVisibility(apiVisibility: ApiVisibilityType): VisibilityType {
  switch (apiVisibility) {
    case ApiVisibilityEnum.All:
      return VisibilityEnum.All;
    case ApiVisibilityEnum.None:
      return VisibilityEnum.None;
    case ApiVisibilityEnum.BranchOnly:
      return VisibilityEnum.BranchOnly;
  }
}
