import { ApiRoleEnum, RoleEnum } from '../enums';
import { ApiRoleType, RoleType } from '../types';

export function mapApiRole(apiRole: ApiRoleType): RoleType {
  switch (apiRole) {
    case ApiRoleEnum.Administrator:
      return RoleEnum.Administrator;
    case ApiRoleEnum.CommunicationManager:
      return RoleEnum.CommunicationManager;
    case ApiRoleEnum.CompensationManager:
      return RoleEnum.CompensationManager;
    case ApiRoleEnum.DownloadsManager:
      return RoleEnum.DownloadsManager;
    case ApiRoleEnum.EducationManager:
      return RoleEnum.EducationManager;
    case ApiRoleEnum.FormManager:
      return RoleEnum.FormManager;
    case ApiRoleEnum.HoursManager:
      return RoleEnum.HoursManager;
    case ApiRoleEnum.HumanResourceManager:
      return RoleEnum.HumanResourceManager;
    case ApiRoleEnum.InspectionManager:
      return RoleEnum.InspectionManager;
    case ApiRoleEnum.LeaveRequestManager:
      return RoleEnum.LeaveRequestManager;
    case ApiRoleEnum.OrderManager:
      return RoleEnum.OrderManager;
    case ApiRoleEnum.PlanningManager:
      return RoleEnum.PlanningManager;
    case ApiRoleEnum.PlanningViewer:
      return RoleEnum.PlanningViewer;
    case ApiRoleEnum.ProjectManager:
      return RoleEnum.ProjectManager;
  }
}

export function mapRole(role: RoleType): ApiRoleType {
  switch (role) {
    case RoleEnum.Administrator:
      return ApiRoleEnum.Administrator;
    case RoleEnum.CommunicationManager:
      return ApiRoleEnum.CommunicationManager;
    case RoleEnum.CompensationManager:
      return ApiRoleEnum.CompensationManager;
    case RoleEnum.DownloadsManager:
      return ApiRoleEnum.DownloadsManager;
    case RoleEnum.EducationManager:
      return ApiRoleEnum.EducationManager;
    case RoleEnum.FormManager:
      return ApiRoleEnum.FormManager;
    case RoleEnum.HoursManager:
      return ApiRoleEnum.HoursManager;
    case RoleEnum.HumanResourceManager:
      return ApiRoleEnum.HumanResourceManager;
    case RoleEnum.InspectionManager:
      return ApiRoleEnum.InspectionManager;
    case RoleEnum.LeaveRequestManager:
      return ApiRoleEnum.LeaveRequestManager;
    case RoleEnum.OrderManager:
      return ApiRoleEnum.OrderManager;
    case RoleEnum.PlanningManager:
      return ApiRoleEnum.PlanningManager;
    case RoleEnum.PlanningViewer:
      return ApiRoleEnum.PlanningViewer;
    case RoleEnum.ProjectManager:
      return ApiRoleEnum.ProjectManager;
  }
}
